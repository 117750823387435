@import url('norm.css');


html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0 0;
  font-family: 'Book Antiqua', sans-serif;
  font-size: 1em;
  -webkit-hyphens: auto;
  hyphens: auto;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.footer-wrapper {
  display: block;
  position: fixed;
  bottom: 0;
  height: 3.6em;
  width: 100%;
  z-index: 5;
  padding: 0 1em;
  margin: 0;
  background: #d7ead9;
}

.Promptdetailsseite,
.Erfassungsseite,
.Einstellungsseite,
.Ergebnisseite,
.Dashboardseite {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  text-align: center;
  position: absolute;
  top: calc(100vh - (88vh - 2.5em));;
}

/* ========================================= Header ==========================================*/

header {
  padding: 0.5remem 2em;
  margin: 0 auto 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: -ms-lang(x);
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  z-index: 5;
  width: 100%;
  text-align: center;
  margin: 0 auto 0;
  background: #006489;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#224889', GradientType=0);
  color: #f0f5f7;
  height: 10%;
  overflow: hidden;
}
header .center {
  position: relative;
  text-align: center;
  color: #f0f5f7;
  font-size: 2.5em;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 60%;
  height: 100%;
  margin: 30px 0px 0px 0px;
}
header .left {
  display: block;
  position: fixed;
  text-align: center;
  color: #f0f5f7;
  left: 2rem;
  margin: 2rem 0rem;
  text-transform: uppercase;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 10%;
}
header .left button {
  display: flex;
  align-items: center;
  -ms-flex-item-align: center;
  border-radius: 7px;
}
header .right {
  display: flex;
  justify-content: center; 
  align-items: center;
  top: 0;
  position: fixed;
  text-align: center;
  color: #f0f5f7;
  right: 2em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 10%;
  padding: 0.5rem 1rem;
}

header .right .logButton div {
  display: flex;
}

/* .welcome-header {
  color: #8ec89a;
  font-size: larger;
  font-weight: 600;
} */
header .right button {
  display: flex;
  align-items: center;
  -ms-flex-item-align: center;
  text-align: right;
  padding: 10px 15px;
  border-radius: 15px;
  position: absolute;
  right: 0;
  background-color:#00425B ;
  font-size: 20px;
  font-weight: bold;
  margin: 0px 0px 0px 10px;
}

header .left p,
header .right p {
  display: inline-block;
  position: relative;
  text-align: center;
  color: white;
  font-size: 1.1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-weight: 500;
  margin: 0 0.5rem;
}
.Logo-header {
  height: 100%;
}
.Logo-header img {
  height: 100%;
}

header .right img {
  display: block;
  position: relative;
  width: auto;
  height: 2.5em;
  padding: 0 0 0 1em;
  float: right;
}

.systemlist {
  width: 50%;
}

.system {
  width: 100%;
}

.system img {
  width: 100%;
}

/*=========================================== Login ====================================*/
.LoginSeite {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  padding: 5em 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  text-align: center;
}

.Logo_login img {
  max-width: 400px;
}
/* Base styles */
.LoginForm {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #0097B2;
  background-color: #EDEDED;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.LoginForm form {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.LoginForm form div {
  text-align: center;
  width: 80%;
  position: relative;
  left: 10%;
}

.LoginForm form label {
  width: 80%;
  font-size: 14px;
  margin-bottom: 6px;
  color: #555;
  display: block;
  text-align: left;
  position: relative;
  left: 5%;
}

.LoginForm form input {
  width: 80%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  display: block;
  position: relative;
  left: 5%;
}

button {
  background-color: #006172;
  color: #fff;
  font-size: 18px;
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0097B2;
}

/* ===================================== NavigationList==================================================  */
nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1vh 0px;
  position: absolute;
  top: 10vh;
}
nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
}

nav ul a {
  width: 20%;
  color: white;
  height: 2.5em;
  margin: 0 5%;
  text-decoration: none;
  text-align: center;
  background-color: #A6A6A6;
  font-weight: bold;
}

nav ul a li {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex; /* Use flex layout */
  align-items: center; /* Vertically center content */
  justify-content: center;
}

.active {
  background-color: #006489;
  color: white;
}

/*======================================== Erfassunsseite =============================================*/
.Erfassungsseite {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}
.wrapper {
  width: 100%;
  height: calc(88vh - 2.5em);
  display: flex;
  flex-direction: row;
}

.SeiteForm {
  flex: 1; /* 25% width */
  padding: 1rem; /* Optional padding for SeiteForm */
  overflow-y: scroll;
}

.HauptForm {
  flex: 3; /* 75% width */
  padding: 1rem; /* Optional padding for HauptForm */
  overflow-y: scroll;
}
.HauptForm .chart1{
  height: 8cm;
  width: 15cm;
  background-color: #bee6c6;
}

.OptionForm {
  padding: 1rem;
  margin: 0 0.5rem;
  background-color: #EDEDED;
  border: 1px solid #006489;
  border-radius: 10px;
}

.PromptsArt,
.SuchTyp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PromptsArt label {
  text-align: left;
  flex: 1;
  font-weight: 600;
  margin-right: 8px; /* Optional margin for spacing between label and select */
}

.PromptsArt select {
  text-align: left;
  /* flex: 1; */
  width: 200px;
}
.SuchTyp {
  text-align: left;
  font-weight: 600;
}
.SuchTyp select{
  width: 200px;
}
#kategorie{
  
}

.kategorien {
  border: 1px solid #006489;
  border-radius: 10px;
}
.tags {
  border: 1px solid #006489;
  border-radius: 10px;
}

.kategorien select {
  width: 100%;
}

.KategorieForm,
.UnterkategorieForm {
  /* margin: 0.3rem; */
}
.KategorieForm .inputFeld{
  margin: 5px 0px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
}

.KategorieForm .inputFeld label{
  flex: 1 1;
  text-align: left;
}
.KategorieForm .inputFeld input{
  flex: 2 1;
  height: 30px;
  box-sizing: border-box;
  padding: 5px;
}

.UnterkategorieForm .inputFeld{
  margin: 5px 0px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
}

.UnterkategorieForm .inputFeld label{
  flex: 1 1;
  text-align: left;
}
.UnterkategorieForm .inputFeld input{
  flex: 2 1;
  height: 30px;
  box-sizing: border-box;
  padding: 5px;
}

.kategorien input {
  height: 1.5rem;
}

.Tags-container {
  text-align: justify;
}

.Eigenschaften-container {
  text-align: justify;
}

.tags input {
  height: 1.5rem;
  border-radius: 3px;
}

.kategorien .buttonsFeld {
  display: flex;
  justify-content: space-between;
}

.kategorien .buttonsFeld .abbrechenBtn {
  width: 120px;
  background-color: white;
  color: grey;
  border: 1px solid grey;
  height: 2rem;
  padding: 5px 5px;
  margin: 10px 0px 5px 0px;
}

.kategorien .buttonsFeld .submitBtn {
  width: 120px;
  background-color: white;
  border: 1px solid #008d36;
  color: #008d36;
  height: 2rem;
  padding: 5px 5px;
  margin: 10px 0px 5px 0px;
}

.bemerkung {
  height: auto;
  text-align: center;
  width: 100%;
  height: fit-content;
  margin: 0;
}

.bemerkung h3 {
  display: inline-block;

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#224889', GradientType=0);
  float: left;
  padding: 0.5em 1em;
  margin: 1em 0 0;
  color: #fff;
  border-radius: 7px 7px 0 0;
}
.bemerkung label {
  display: inline-block;

  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#224889', GradientType=0);
  float: left;
  padding: 0.5em 1em;
  margin: 1em 0 0;
  color: #fff;
  border-radius: 7px 7px 0 0;
  font-size: 1.2em;
}
.bemerkung input {
  border-radius: 0 7px 7px 7px;
  min-height: 5em;
  padding: 1em;
  font-size: 1.2em;
}
.bemerkung textarea {
  border-radius: 0 7px 7px 7px;
  min-height: 10em;
  padding: 1em;
  width: auto;
}
/*================================== Structural Chat =======================================*/
.structuralChat {
  padding: 5%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  text-align: left;
}

.structuralChat label {
  display: block;
  font-weight: bold;
  margin: 0px 5px 5px 0px;
}

.structuralChat input[type='text'],
.structuralChat input[type='number'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
  box-sizing: border-box;
}

.structuralChat select {
  cursor: pointer;
  margin: 0 2rem;
}

.structuralChat form div {
  margin: 1rem 0;
}

.structuralChat select:focus,
.structuralChat input[type='text']:focus,
.structuralChat input[type='number']:focus {
  outline: none;
  border-color: #007bff;
}

.structuralChat select[name='stil'],
.structuralChat select[name='emojis'],
.structuralChat select[name='sprache'] {
  width: 49%;
  margin: 0px;
}

/* Style the form labels */
.structuralChat form div label {
  display: block;
  margin-bottom: 5px;
}

/* Adjust the layout for the range inputs */
.structuralChat [name='länge.min']{
  margin-right: 2%;
}
.structuralChat [name='länge.min'],
.structuralChat [name='länge.max'] {
  width: 49% !important;
}

/* Style the 'Platform' input */
.structuralChat .PropertiesInput[label='Platform'] {
  background-color: #fff; /* Change this to your preferred background color */
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Style the 'Aufgabe' input for chatType 'option1' */
.structuralChat input#eingabeText {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 16px;
}

.structuralChat .chat-row-container {
  margin: 1rem 0;
}
.structuralChat .chat-row-container select{
  width: 230px;
  height: 40px;
}
.structuralChat .chat-row-container label{
  width: 100px;
}
.structuralChat .chat-row-container input{
  width: 640px;
}
.structuralChat select{
  height: 40px;
}
/* ====================== Ergebnis, Eingabe, Kategorien, Notiz, speicherButton, Tags-container =============================*/

.eingabe,
.kategorien,.tags {
  padding: 1rem;
  margin: 0.5rem;
}
.speicherButton{
  width: 94%;
  padding: 10px 20px 20px 20px;
}


.eingabe,
.kategorien {
  background-color: #EDEDED;
}

textarea {
  width: 80%;
  height: 3rem;
  margin: 0.5rem;
  border-radius: 3px;
}

select {
  margin: 0.5rem 0;
  height: 1.5rem;
  font-size: larger;
  border-radius: 3px;
}

.addButton {
  font-size: 24px;
}

button {
  margin: 0.5rem;
}

/* CSS for the chat interface */
.chat-container {
  width: 94%;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #006489;
  background-color: #fff;
}

.chat-row-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.message {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

.user {
  background-color: #007bff;
  color: #fff;
  text-align: right;
}

.bot {
  background-color: #f0f0f0;
  color: #333;
  text-align: left;
}

.input-form {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0.5rem;
}

.input-form input[type='text'] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.input-form button[type='submit'][disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}
.speicherButton button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

#inputText{
  box-sizing: border-box;
  margin: 0px;
  width: 100%;
  resize:none;
  height: 80px;
}

.showBlock1-Input{
  padding: 0.5rem;
}

.output {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}
.output h2 {
  text-align: left;
}
.input-form h2 {
  text-align: left;
}
.output textarea {
  width: 100%;
  resize:none;
  height: 150px;
  font-size: 1.5rem;
  background-color: #f1f1f1;
  margin: 0px;
  box-sizing: border-box;
}

.input-form {
  display: flex;
  flex-direction: column;
}

.input-form .row1 {
  width: 100%;
}

.showBlock1-Input input {
  width: 100%;
  position: relative;
  right: 0.5rem;
}

/* Additional styles for responsiveness */
@media (max-width: 768px) {
  .chat-container {
    width: 100%;
    border-radius: 0;
  }
  .input-form {
    flex-direction: column;
  }
  .input-container {
    flex-direction: column;
  }
  .input-form button[type='submit'] {
    margin-top: 10px;
  }
}

.submitPrompt {
  margin-top: 10px;
}

/*================================== Ergebnisseite===============================*/
.Ergebnisseite .wrapper {
  width: 100%;
  height: calc(88vh - 2.5em);
  display: flex;
  flex-direction: row;
}
/* .Ergebnisseite .wrapper .left-block {
  flex: 1 1;
  padding: 0px 1rem;
  overflow-y: scroll;
}
.Ergebnisseite .wrapper .right-block {
  flex: 3 1;
  padding: 0px 1rem;
  overflow-y: scroll;
}

.Ergebnisseite .wrapper .right-block .chart1{
  height: 8cm;
  width: 15cm;
  background-color: #bee6c6;
} */
.search{
  border-radius: 5px;
  background-color: #D9D9D9;
  margin: 10px 0px;
  padding: 20px;
}

table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
}

td {
  word-wrap: break-down;
}

table,
th,
td {
  border: 1px solid #ccc;
}

th,
td {
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}
/* Apply alternating background colors to table rows */
tr:nth-child(even) {
  background-color: #f5f5f5; /* Even rows */
}

tr:nth-child(odd) {
  background-color: #ffffff; /* Odd rows */
}

/* Styling for table rows on hover */
tr:hover {
  background-color: #b3d9ff; /* Hover effect */
}

/*============================================ Swipeable Item ===============================================*/
/* SwipeableListItem.css */
.leading-actions {
  display: flex;
}

.leading-actions > * {
  width: 0;
  overflow-x: hidden;
}

.swipeable-list {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.swipeable-list-item {
  position: relative;
  transition: max-height 0.5s ease-in-out;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.swipeable-list-item--remove {
  max-height: 0;
  transition: max-height 0.35s ease-out;
}

.swipeable-list-item__content {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  display: flex;
}

.swipeable-list-item__content--return {
  transition: transform 0.5s ease-in-out;
}

@-webkit-keyframes content-return-leading-ms {
  20% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes content-return-leading-ms {
  20% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.swipeable-list-item__content--return-leading-ms {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: content-return-leading-ms;
  animation-name: content-return-leading-ms;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes content-return-trailing-ms {
  20% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes content-return-trailing-ms {
  20% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.swipeable-list-item__content--return-trailing-ms {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: content-return-trailing-ms;
  animation-name: content-return-trailing-ms;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.swipeable-list-item__content--remove {
  transition: transform 0.5s ease-in-out;
}

.swipeable-list-item__leading-actions {
  position: absolute;
  display: flex;
  width: 0px;
  overflow: hidden;
  height: 100%;
  background-color: orange;
}

.swipeable-list-item__leading-actions--return {
  transition: width 0.5s ease-in-out;
}

@-webkit-keyframes actions-return-ms {
  20% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

@keyframes actions-return-ms {
  20% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.swipeable-list-item__actions--return-ms {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: actions-return-ms;
  animation-name: actions-return-ms;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.swipeable-list-item__leading-actions > * {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  transform: scale(1);
  transform-origin: center left;
  transition: transform 0.2s ease-out;
}

.swipeable-list-item__trailing-actions {
  display: flex;
  width: 0px;
  position: absolute;
  right: 0;
  top: 0;
  justify-content: flex-end;
  overflow: hidden;
  height: 100%;
  background-color: red;
}

.swipeable-list-item__trailing-actions--return {
  transition: width 0.5s ease-in-out;
}

.swipeable-list-item__trailing-actions > * {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  transform: scale(1);
  transform-origin: center right;
  transition: transform 0.2s ease-out;
}

.swipeable-list-item__leading-actions--scaled > * {
  transform: scale(1.2);
  transform-origin: center left;
  transition: transform 0.2s ease-in;
}

.swipeable-list-item__trailing-actions--scaled > * {
  transform: scale(1.2);
  transform-origin: center right;
  transition: transform 0.2s ease-in;
}

.swipe-action {
  width: 100%;
  align-items: stretch;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: xx-large;
  padding: 2.8rem 1rem;
  justify-content: center;
}

.swipe-action > * {
  flex: 1;
}

.swipe-action__leading > * {
  justify-content: flex-start;
}

.swipe-action__trailing > * {
  justify-content: flex-end;
}

.swipe-action__leading--full-swipe-rest,
.swipe-action__trailing--full-swipe-rest {
  width: 0;
  transition: width 0.2s;
}

.swipe-action__leading--full-swipe-main > * {
  justify-content: flex-end;
}

.swipe-action__trailing--full-swipe-main > * {
  justify-content: flex-start;
}

.trailing-actions {
  display: flex;
}

.trailing-actions > * {
  width: 0;
  overflow-x: hidden;
}
.filterBereiche{
  display: flex;
  flex-direction: column; /* Align elements vertically */
  align-items: flex-start;
  margin-left: 120px;
}
.bookmark{
  height: 20px;
}

.PromptSwipeable {
  user-select: none;
  /* cursor: pointer; */
  background-color: #EDEDED;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
  margin: 10px 0;
  height: 120px;
  width: 100%;
}
.PromptSwipeable .bookmark{
  position: absolute;
  top: 20px;
  right: 2.5%;
}
.PromptSwipeable .next{
  width: 8px;
}
.back{
  width: 9px;
  rotate: 180deg;
  margin: 0px 3px 0px 0px;
}
.PromptSwipeable .row-1 {
  font-weight: bold;
}
.PromptSwipeable .row-2 span {
  font-weight: bold;
}
.PromptSwipeable .row-3 span {
  font-weight: bold;
}

.PromptSwipeable {
  width: 100%;
  height: auto;
}
.PromptSwipeable .row-1 {
  height: 35%;
  width: 95%;
  padding: 10px 0 0 0;
  text-align: justify;
}
.PromptSwipeable .row-2 {
  height: 35%;
  width: 95%;
  padding: 10px 0 0 0;
  text-align: justify;
}
.PromptSwipeable .row-3 {
  height: 45%;
  width: 95%;
  padding: 10px 0 0 0;
  text-align: justify;
}

.text-container {
  max-width: 100%; /* Set the maximum width for the container */
  overflow: hidden; /* Hide the overflow text */
  display: block; /* Display the text as a block element */
  white-space: normal;
  white-space: pre-line;
}

.text-container-2rows {
  max-height: 2em; /* Limit to 2 rows (adjust the height as needed) */
  line-height: 1em; /* Set line height to control row height */
}

.text-container-3rows {
  max-height: 3em; /* Limit to 3 rows (adjust the height as needed) */
  line-height: 1em; /* Set line height to control row height */
}

.PromptSwipeable .row-4 {
  font-size: small;
  color: #888;
  width: 95%;
  height: 20%;
  padding: 10px 20px 10px 0;
  text-align: right;
}

.PromptSwipeable .row-4 svg {
  font-size: large;
  margin: 0 5px;
}
.PromptSwipeable .row-4 span {
  margin: 0.5rem;
}

.PromptSwipeable .row-4 a {
  text-decoration: none;
  color: grey;
}

/* ================================= PromptItemDetails =============================== */
.PromptItemDetails {
  padding: 20px;
  margin: 10px;
  max-width: 100%; /* Adjust the width as needed */
}

.details-title {
  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center the content */
}

.details-title h2 {
  margin: 0;
}

.details-title input {
  font-size: larger;
}

.details-eingabe,
.details-ausgabe,
.details-comment,
.details-notiz {
  margin: 10px 0;
  padding: 10px;
  text-align: justify;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.PromptItemDetails .spanTitel {
  font-weight: 800;
}

.PromptItemDetails .spanContent {
  white-space: pre-line;
}

.PromptItemDetails .spanComment {
  white-space: pre-line;
}

.PromptItemDetails .spanButton {
  position: absolute;
  right: 3em;
  color: #008d36;
  cursor: pointer; /* Change cursor to a pointer on hover */
  transition: all 0.3s ease; /* Add a smooth transition for the hover effect */
}

.spanButton:hover {
  background: transparent;
  color: grey; /* Change text color on hover */
  transform: scale(1.1); /* Scale the element by 10% on hover (zoom effect) */
}

.PromptItemDetails .popup {
  text-align: center;
  margin: 5px 40%;
  background-color: white;
  color: #008d36;
}

.details-eingabe {
  background-color: #A8DFE1;
  height: auto;
}

.details-ausgabe {
  background-color: #ABE1D0;
  height: auto;
}

.details-comment {
  background-color: #f1f1f1;
}

.details-notiz {
  /* Style for the notes section, if needed */
}

.details-datum,
.details-autor,
.details-bereich {
  text-align: right;
  margin: 10px 0;
}

.details-mark {
  text-align: right;
  font-size: x-large;
}

.PromptItemDetails h4 {
  font-weight: 600;
  text-align: left;
}

.details-eigenschaften,
.details-tags {
  margin: 2rem 1rem;
}

.details-eigenschaften svg {
  font-size: x-large;
}
.details-tags svg {
  font-size: x-large;
}

.details-eigenschaften-container,
.details-tags-container {
  text-align: justify;
}

/* Add additional styles as needed for specific elements */

/*=============================== Tag Button =======================================*/

.Eigenschaft-button {
  margin: 5px;
  padding: 5px 10px;
  background-color: #1e90ff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 1rem;
}

.Tag-button {
  margin: 5px;
  padding: 5px 10px;
  background-color: #ff8c00;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 1rem;
}

.deleteButton {
  font-size: 20px;
  padding-left: 3px;
  border-radius: 50%;
}
/* Optional: To ensure equal spacing between buttons in a row */
.Eigenschaft-button,
.Tag-button:not(:last-child) {
  margin-right: 10px;
}

.EigenschaftItem,
.TagItem {
  width: auto;
  display: inline-block;
  margin: 0.5rem 1rem;
}
/* Optional: To control the number of buttons per row */
@media screen and (max-width: 768px) {
  /* .tag-button-container {
    justify-content: center; /* Center the buttons on smaller screens 
  } */
}

/* ====================================== Einstellungsseite ==========================*/
.Einstellungsseite .wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 18px;
}
.Einstellungsseite .wrapper .SeiteForm {
  flex: 1 1;
  padding: 1rem;
}

.Einstellungsseite .wrapper .SeiteForm ul {
  list-style: none;
  padding: 0;
  justify-content: center;
  /* Background color for the entire list */
}

.Einstellungsseite .wrapper .SeiteForm ul li {
  margin: 5px 0;
  cursor: pointer;
  background-color: #0097B2;
  color: #ffffff; /* Text color */
  font-weight: bold; /* Make text bold */
  padding: 12px 12px; /* Add padding to create clickable areas */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth background 
  color transition */
}

.Einstellungsseite .wrapper .SeiteForm ul li:hover {
  background-color: #006172; /* Background color on hover */
  color: white;
}

.Einstellungsseite .wrapper .SeiteForm ul li.active {
  background-color: #006172; /* Background color on hover */
  color: white;
}

.Einstellungsseite .wrapper .HauptForm {
  flex: 3 1;
  padding: 1rem;
}

.chart-background{
  background-color:  #A8DFE1;
  padding: 1rem;
}
.Einstellungsseite .wrapper .HauptForm tr .td1 {
  text-align: left;
  font-weight: bold;
}
.td2{
  width: 1000px;
  max-width: 1000px;
}
.Einstellungsseite .wrapper .HauptForm tr .td2 div {
  width: 100%;
  display: flex;
}
.Einstellungsseite .wrapper .HauptForm tr .td2 div input,
.Einstellungsseite .wrapper .HauptForm tr .td2 div span,
.Einstellungsseite .wrapper .HauptForm tr .td2 div select {
  /* flex: 75%; */
  /* padding: 20px; */
  /* text-decoration:underline; */
  width: 75%;
  max-width: 75%;
  box-sizing: border-box;
  text-align: left;
  overflow-x: auto;
  /* flex-wrap: wrap; */
}
.Einstellungsseite .wrapper .HauptForm tr .td2 div input[type='checkbox']{
  width: 20px;
  margin: 0px 10px;
}

.Einstellungsseite .wrapper .HauptForm tr .td2 div button {
  box-sizing: border-box;
  /* flex: 1; */
  /* margin-left: 20px; */
  margin: 0px;
  width: 255px;
}

.td2 div span {
  display: flex;
  align-items: center;
}

.td2 div input {
  padding: 8px 10px;
  border-radius: 4px;
}

.passwordInput{
  width: 500px;
  height: 30px;
  margin: 0px 0px 0px 0px;
  border: 1px solid black;
  box-sizing: border-box;
}

.passwordDiv{
  width: 100%;
  display: flex;
}
/* test@test.de */
.passwordDiv span{ 
  /* flex: 3 1; */
  width: 75%;
  text-align: left;
}

.passwordDiv button{
  box-sizing: border-box;
  /* flex: 1; */
  width: 255px;
  margin: 0px;
}

.BereichHeader td{
  text-align: left;
  background-color: #8a8a8a;
  box-sizing: border-box;
  border: 2px solid #8a8a8a;
}
.firmendatenDiv table{
  margin:0px;
}
.firmendatenDiv td{
  /* border: none; */
  text-align: left;
}
.firmendatenDiv button{
  margin: 0px 5px;
  width: 200px;
}

.neueBereichDiv{
  display:inline-block;
  border:1px solid #8a8a8a;
  box-sizing:border-box;
  width:100%;
  height:200px;
}
.neueBereichDiv h3{
  margin: 5px;
}
.neueBereichDiv div input{
  width:400px;
  height:35px;
  box-sizing:border-box;
  padding:0px 5px;
  font-size:18px;
}
.neueBereichDiv button{
  margin:10px 5px
}
.BereichHeader p{
  width: 50px;
  cursor: pointer;
}
.BereichHeader span{
  font-size: 20px;
  font-weight: bold;
}

.BenutzerDetailHeaderTable{
  margin: 0px;
}
.BenutzerDetailHeader{
  text-align: right;
  background-color: #8a8a8a !important;
  box-sizing: border-box;
  border: 2px solid #8a8a8a;
}
.BenutzerDetailHeader p{
  float: right;
  width: 100px;
  margin: 0px 10px 0px 0px;
  cursor: pointer;
}
.neueBenuzerDiv input{
  width: 400px;
  height: 40px;
  box-sizing: border-box;
  margin: 2px;
  font-size: 15px;
  padding: 5px;
}
.neueBenuzerDiv #strPassword {
    -webkit-text-security: disc;
}
.neueBenuzerDiv select{
  width: 400px;
  height: 40px;
  box-sizing: border-box;
  margin: 2px;
  font-size: 15px;
  padding: 5px;
}
.neueBenuzerDiv label{
  width: 100px;
  display:inline-block;
  text-align: left;
}
.neueBenuzerDiv button{
  position: absolute;
  margin-top: 20px;
  right: 30px;
}
.neueBenutzerFormDiv{
  display:inline-block;
  width: 600px;
  position: relative;
}